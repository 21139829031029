import { default as indexzMYDRkZVRmMeta } from "/bokdata/pages/home/index.vue?macro=true";
import { default as indexL6VAO1U55PMeta } from "/bokdata/pages/index.vue?macro=true";
export default [
  {
    name: "home",
    path: "/home",
    component: () => import("/bokdata/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexL6VAO1U55PMeta || {},
    component: () => import("/bokdata/pages/index.vue").then(m => m.default || m)
  }
]